:root {
  --chat-window-bg-color: #fff;
  --chat-content-bg-color: #fff;
}

.chat-window-open {
  display: flex;
  flex-direction: column;
  width: 34.375rem; /* 550px */
  height: 61.25rem; /* 933px */
  max-height: 42.5rem; /* 680px */
  background: var(--chat-window-bg-color);
  padding: 0.9375rem; /* 15px */
  justify-content: space-between;
  align-self: flex-end;
  position: fixed;
  bottom: 1.25rem; /* 100px */
  right: 3.125rem; /* 50px */
  border-radius: 1rem; /* 16px */
  color: #fff;
  gap: 0.625rem; /* 10px */
  box-shadow: 0rem 1.75rem 1.75rem 0rem rgba(50, 50, 71, 0.15),
    0rem 3rem 3.5rem 0rem rgba(50, 50, 71, 0.25); /* Adjusted for readability */
  flex-shrink: 0;
}

.chat-window-closed {
  display: none;
}

.chat-icon-container {
  padding: 0.625rem; /* 10px */
  background-color: #7a92f8;
  border-radius: 0.3125rem; /* 5px */
}

.close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 1.5rem; /* 24px */
  height: 1.5rem; /* 24px */
  border: none;
}

.chat-content-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  max-height: calc(100% - 1.25rem); /* 20px */
  overflow-y: auto;
}

.chat-content-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: #2c2e30;
  font-family: var(--main-font-family);
  font-size: 0.875rem; /* 14px */
  font-weight: 400;
  line-height: 196.6%; /* Consider recalculating line height in rem if needed */
}

.chat-footer {
  border-radius: 0.5rem; /* 8px */
  background-color: var(--chat-content-bg-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.chat-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem; /* 8px */
  width: 100%;
  height: 100%;
  border-radius: 0.25rem; /* 4px */
  border: 0.0625rem solid var(--Mystic-Grey, #e3e6e8); /* 1px */
  background: #fff;
  padding: 0.5rem; /* 8px */
}

.chat-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 1.5rem; /* 24px */
  height: 1.5rem; /* 24px */
  border: none;
  background: none !important;
  cursor: pointer;
}

.chat-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.625rem; /* 10px */
}

.chat-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0.9375rem 0 0; /* 15px 0 0 */
  width: 100%;
}

.chat-content {
  overflow: auto;
  background-color: var(--chat-content-bg-color);
}

.chat-form {
  width: 100%; /* Ensure it fills its parent */
  box-sizing: border-box; /* Include padding and border in its width calculation */
}

.chat-form .input-container {
  display: flex; /* Align children in a row */
  align-items: center; /* Center-align the items vertically */
  width: 100%; /* Full width of the parent container */
  padding: 0.3125rem; /* Uniform padding */
  border-radius: 0.5rem; /* Rounded corners */
  border: 0.0625rem solid #d6dae4; /* Specified border */
  box-sizing: border-box; /* Padding and border included in width */
}

.chat-form .user-input-box {
  flex-grow: 1; /* Input takes up available space */
  margin-right: 0.3125rem; /* Space between input and button */
  border: none; /* No border for input */
  outline: none; /* No outline */
  padding: 0.3125rem; /* Padding inside input */
  height: auto; /* Adjust height as necessary */
  align-self: center; /* Center the input if align-items doesn't fully correct the alignment */
}

.send-message-button {
  background: none; /* Clear background */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor */
  padding: 0; /* No padding */
  display: flex; /* Use flex to center the image inside the button */
  align-items: center; /* Center the image vertically */
}

.chat-form .send-message-button img {
  width: 1.5rem; /* 24px */
  height: 1.5rem; /* 24px */
}

.chat-window-button {
  width: 3.5rem; /* 56px */
  height: 3.5rem; /* 56px */
  background-color: var(--chat-window-bg-color);
  border-radius: 50%;
  position: fixed;
  right: 3.125rem; /* 50px */
  bottom: 1.875rem; /* 30px */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

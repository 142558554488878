.text-row {
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 100%;
  margin-bottom: 1.5vh;
}

.bot-text-row {
  justify-content: left;
}

.user-text-row {
  justify-content: right;
}

.icon-container {
  width: 52px;
}

.text-row .ai-icon {
  max-height: 2rem;
}

.text-row .user-icon {
  align-items: center;
  background-color: #000000;
  color: #fff;
  display: flex;
  font-size: 10px;
  justify-content: center;
  text-transform: uppercase;
}

.ai-icon,
.user-icon,
.empty-space {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 32px;
  justify-content: center;
  margin: 0 10px;
  width: 32px;
}

.text-row .ai-icon img {
  height: 32px;
  width: 32px;
}

.bot-text-bubble,
.user-text-bubble {
  border: 1px solid #dce2ea;
  color: #222;
  /* flex-grow: 1; */
  width: fit-content;
  max-width: 100%;
  font-family: var(--main-font-family);
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  padding: 10px;
}

.bot-text-bubble {
  border-radius: 0 8px 8px 8px;
  text-align: left;
}

.user-text-bubble {
  border-radius: 8px 0 8px 8px;
  text-align: right;
}

.text-bubble-text {
  margin: 0;
}
